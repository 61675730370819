<template>
    <div>
     <table class="table">
      <thead class="thead-dark">
        <tr>
            <th scope="col" class="bold" v-for="(field,fIndex) in fields" :key="'d'+fIndex">{{field.label}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(record,rIndex) in records" :key="'r'+rIndex">
            <td scope="row" v-for="(field,fIndex) in fields" :key="'rec'+fIndex">
                <span v-if="field.type == 'operation'">
                    <template v-for="(operation,opIndex) in field.operations" :key="opIndex" >
                       

                        <button :class="opIndex == 0?'bg-success':'bg-danger'" @click="$emit('handleOperation',operation,record)">{{operation}}</button>
                    
                    </template>
                </span>
                <span v-else>{{record[field.path]}}</span>
            </td>
        </tr>
      </tbody>
     </table>
    </div>
</template>

<script>
    export default {
        name: 'Table',
        props: ['fields','records']
    }
</script>

<style scoped>


</style>