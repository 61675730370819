<template>
  <form  class="form-container" @submit.prevent="$emit('updateFields')">
    <h1>Login</h1>
    <template v-for="(eField,eFIndex) in fields" :key="eFIndex">
      <div :class="eField.editCss" v-if="eField.type == 'data'">
      <label :for="eField.label"><b>{{eField.label}}</b></label>
      <input type="text" :readonly="eField.isNonEditable"  v-model="eField.model" :placeholder="'Enter '+eField.label" :name="eField.label" required>
    </div>
    </template>

    <button type="submit" class="btn">Save</button>
    <button type="button" class="btn cancel" @click="$emit('close')">Close</button>
  </form>
</template>

<script>
export default {
    name: 'Popup',
    props: ['fields'],
    data(){
        return {
            isShow: true,
        }
    }

}
</script>

<style scoped>
/* Button used to open the contact form - fixed at the bottom of the page */
.open-button {
  background-color: #555;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 280px;
}

/* The popup form - hidden by default */
.form-popup {
  display: block;
  position: fixed;
  bottom: auto;
  right: auto;
  bottom: auto;
  left: auto;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

/* Add styles to the form container */
.form-container {
  max-width: 94%;
  padding: 10px;
  background-color: white;
}

/* Full-width input fields */
.form-container input[type=text], .form-container input[type=password] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
}

/* When the inputs get focus, do something */
.form-container input[type=text]:focus, .form-container input[type=password]:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.form-container .btn {
  background-color: #04AA6D;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom:10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover, .open-button:hover {
  opacity: 1;
}

</style>