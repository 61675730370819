<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <label>IoT Device Name</label>
                <DropDown id="device" name="device"  :options="dropDownOptions" @selected="handleSelect" />
            </div>
            <div class="col-sm-4">
                <div> 
                    <DatePicker id="range11" name="range11" label="Start Date" v-model="rangeApiObj.range1" @update:modelValue="handleRangeChange"/> 
                </div>
            <div>  
                <DatePicker id="range22" name="range22" label="End Date" v-model="rangeApiObj.range2" @update:modelValue="handleRangeChange"/>
           </div>
            </div>
            <div class="col-sm-2">
                <button  :disabled="devicehistory.length==0" class="btn btn-primary btn-md btn-block" @click="exportExcel">Download Excel</button>
                </div>
        </div>
       

        <div class="row" v-if="devicehistory.length>0">
          <div class="table-container">
        <Table :fields="tableFields" :records="devicehistoryCopy"></Table>
      </div>
        </div>
        <div class="row" v-show="devicehistory.length>0">
            <div class="col-sm-6"><p class="float-start"></p></div>  
            <div class="col-sm-6 float-end"><paginate
                :page-count="paginationMeta.pageCount"
                :page-range="paginationMeta.pageRange"
                :margin-pages="paginationMeta.marginPages"
                :click-handler="clickCallback"
                :prev-text="paginationMeta.prevText"
                :next-text="paginationMeta.nextText"
                :container-class="paginationMeta.containerClass"
                :page-class="paginationMeta.pageClass"
                first-last-button="true"
                first-button-text="First"
                last-button-text="Last"
            >
            </paginate></div> 
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Paginate from "vuejs-paginate-next";
import axios from 'axios';
import DropDown from './DropDown.vue';
import DatePicker from './DatePicker.vue';
import Table from './Table.vue';
export default {
    name: 'Report',
    props: [],
    components: {
    DropDown,
    DatePicker,
    Table,
    Paginate
},
    data() {
        return {
            dropDownOptions: [],
            currentDeviceSelected: '',
            currentPage: 1,
            pageSize: 10,
            paginationMeta: {
                pageCount: 0,
                pageRange: 5,
                marginPages: 2,
                prevText: 'Prev',
                nextText: 'Next',
                containerClass: 'pagination',
                pageClass: 'page-item'

            },
            rangeApiObj: {
                range1: '',
                range2: ''
            },
            devicehistory: [],
            devicehistoryCopy: [],
            tableFields:[
                // {label: 'Device ID',path: 'deviceId'},
                // {label: 'Lattitude',path: 'latitude'},
                // {label: 'Longitude',path: 'longitude'},
                {label: 'TimeStamp (GMT) ',path: 'currentTimestamp'},
                {label: 'TDS INLINE (PPM)',path: 'tdsSens1'},
                {label: 'TDS RO (PPM)',path: 'tdsSens2'},
                {label: 'TDS BLENDED  (PPM)',path: 'tdsSens3'},
                {label: 'TEMPERATURE RECYCLE (°C)',path: 'tempSens1'},
                {label: 'TEMPERATURE RO (°C)',path: 'tempSens2'},
                {label: 'TEMPERATURE WASTE (°C)',path: 'tempSens3'},
                {label: 'RECYCLE WATER FLOW (GPM)',path: 'flowSens1'},
                {label: 'RO WATER FLOW (GPM)',path: 'flowSens2'},
                {label: 'WASTE WATER FLOW (GPM)',path: 'flowSens3'},
               
            ]
        };
    },
    created() {
        this.fetchDevice();
    },
    computed: {
        ...mapState({
            user: (state) => state.appModule.user
        })
    },
    methods: {
        ...mapMutations(['setLoading']),
        clickCallback(obj){ // pagination call back
          console.log(obj)
          let endIndex = parseInt(obj)*this.pageSize-1;
          let startIndex = endIndex-this.pageSize;
          this.devicehistoryCopy = this.devicehistory.slice(startIndex+1,endIndex+1);
          console.log('StartIndex ',startIndex,' endIndex ',endIndex)
        },
        handleRangeChange(){ // from datepicker
           this.fetchChartData({...this.rangeApiObj,deviceId:this.currentDeviceSelected}); 
            
        },
        handleSelect(value){ // handler for onSelect device
          this.currentDeviceSelected = value;
          this.fetchChartData({...this.rangeApiObj,deviceId:this.currentDeviceSelected});
        },
        async fetchDevice() {
            this.setLoading(true);
            await axios.get(process.env.VUE_APP_GET_SENSOR_LIST, { headers: { 'Authorization': `Bearer ${this.user.token}` } })
                .then(res => {
                    setTimeout(()=>this.setLoading(false),1)
                if (res.status === 200) {
                    this.dropDownOptions = res.data.map((rec,index) => {
                        if(index == 0){
                            this.currentDeviceSelected = rec.deviceId;
                        }
                        return { value: rec.deviceId, label: rec.customerName };
                    });
                }
            })
                .catch(er => {
                console.error(er);
                this.setLoading(false);
            });
        },
        fetchChartData({deviceId,range1,range2}){
            if(!deviceId || !range1 || !range2){
                return;
            }
            let fromDate = new Date(range1).toISOString().substring(0,19).replaceAll('T',' ');
            // let toDate = new Date(range2).toISOString().substring(0,19).replaceAll('T',' ');
            let toDate = range2 == new Date().toISOString().substring(0,10) ? new Date().toISOString().substring(0,19).replace('T',' ') : new Date(range2).toISOString().substring(0,19).replaceAll('T',' ');
            console.log(range2,new Date().toISOString().substring(0,10));
            let reqParams =`?deviceId=${deviceId}&fromDate=${fromDate}&toDate=${toDate}`;
        axios.get(process.env.VUE_APP_GET_DEVICE_HISTORY+reqParams,{headers:{'Authorization': `Bearer ${this.user.token}`}})
          .then((res)=>{
            if(res.status === 200){
               this.devicehistory = res.data;
               this.devicehistory.forEach(rec=>{
                rec.currentTimestamp = rec.currentTimestamp.substring(0, 19).replace('T',' ');
                return rec;
               });
               this.paginationMeta.pageCount = this.devicehistory.length%this.pageSize>0?Math.trunc(parseInt(this.devicehistory.length)/parseInt(this.pageSize)+1):parseInt(this.devicehistory.length)/parseInt(this.pageSize);
               this.devicehistoryCopy = this.devicehistory.slice(0,this.pageSize);
            }
          })
          .catch(er=>console.log(er))

        },
        exportExcel(){
          let deviceId=this.currentDeviceSelected;
          const {range1,range2}=this.rangeApiObj;
            let fromDate = new Date(range1).toISOString().substring(0,19).replaceAll('T',' ');
            let toDate = new Date(range2).toISOString().substring(0,19).replaceAll('T',' ');
            let reqParams =`?deviceId=${deviceId}&fromDate=${fromDate}&toDate=${toDate}`;
         axios.get(process.env.VUE_APP_EXPORT_EXCEL+reqParams,
                {
                  headers:{
                    'Authorization': `Bearer ${this.user.token}`,
                          },
                          responseType:"blob",}
                 )
          .then((res)=>{
            if (res.status === 200) {
            let resType = res.headers['content-type'];
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([res.data],{ type: resType, encoding: 'UTF-8' }));
            link.setAttribute('download', 'Paragon.xls');
            document.body.appendChild(link);
            link.click();
           
          }
          })
          .catch(er=>console.log(er))

        }
    }
}
</script>

<!-- <style lang="scss" scoped></style> -->
<style scoped>
  /* Container Styles */
  .container {
    background-color: #f5f5f5; /* Background color for the entire container */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 1325px;
  }

  /* Label Styles */
  label {
    color: #3498db; /* Label text color */
  }

  /* Date Picker Styles */
  .date-picker {
    margin-top: 10px;
  }

  /* Button Styles */
  .btn-primary {
    background-color: #e74c3c; /* Primary button background color */
    border-color: #e74c3c; /* Primary button border color */
  }

  /* Table Styles */
  table {
    background-color: #fff; /* Table background color */
    border: 1px solid #dcdcdc;
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
  }

  th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dcdcdc;
  }

  th {
    background-color: #f2f2f2; /* Table header background color */
    font-weight: bold;
  }

  /* Pagination Styles */
  .pagination {
    margin-top: 20px;
  }

  .page-link {
    background-color: #e74c3c; /* Pagination button background color */
    color: #fff; /* Pagination button text color */
    border-color: #e74c3c; /* Pagination button border color */
  }

  
  .table-container {
    /*  max-height: 300px;Set the maximum height according to your design */
    overflow-y: scroll;
  }

</style>
