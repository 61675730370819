<template>
    <template v-for="(alert, alIndex) in messages" :key="alIndex">
        <div :class="['alert center', 'alert-'+alert.type]" role="alert" >
            {{alert.message}}
        </div>
    </template>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
    name: 'Alert',
    methods: {
        ...mapMutations(['clearAlerts']),
        setTempTime(){
            setTimeout(() => this.clearAlerts(), 1000 * 5);
        }
    },
    computed: {
        ...mapState({
            alerts: (state) => state.appModule.alerts,
        }),
        messages() {
            if (this.alerts.length > 0) {
                this.setTempTime();
                return this.alerts;
            }
            else {
                return this.alerts;
            }
        }
    }


}
</script>

<style></style>