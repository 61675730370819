<template>
    <label :for="name">{{ label }}</label>
    <input type="date" v-model="modelValue"  :id="id" @change="handleDate"/>
</template>

<script>
    export default {
        name: 'DatePicker',
        props: ['id','name','label'],
        methods: {
            handleDate(e){
                let  dt = new Date(e.target.value);
                this.$emit("update:modelValue",`${dt.toISOString().substring(0,10)}`);
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>