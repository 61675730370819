<!-- <template>
    <GoogleMap
      api-key="AIzaSyDx3jZ84uKHJyToJJd911vsa_iods5Yhq4"
      style="width: 100%; height: 500px" :center="center" :zoom="15">
    <Marker :options="{ position: center }" />
  </GoogleMap>
</template>
<script>
import { defineComponent } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";

export default defineComponent({
  components: { GoogleMap, Marker },
  setup() {
    const center = { lat: 40.689247, lng: -74.044502 };

    return { center };
  },
});
</script> -->

<template>
  <GoogleMap
    api-key="AIzaSyDx3jZ84uKHJyToJJd911vsa_iods5Yhq4"
    style="width: 100%; height: 500px" :center="{ lat: this.latt, lng: this.longt }" :zoom="15">
  <Marker :options="{ position: { lat: this.latt, lng: this.longt } }" />
</GoogleMap>
</template>
<script>
import { defineComponent } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";

export default {
  name: 'Map',
  components: {
      GoogleMap, Marker
  },
  props: ['latt','longt']
}
</script>