<template>
  <nav class="navbar navbar-expand-md  " style="background-color: #085990; ">
<!-- <div class=".navbar header"> -->
  <a class="navbar-brand" href="#"> 
    <img src="../assets/pws.jpg" alt="Pws" width="120" height="50">
</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" style="font-size: medium; font-weight: bold;" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto custom-ul-style">
      <template v-for="(menu,index) in menuList" :key="menu.id">
        <li class="nav-item active" :class="[index == currentIndex?'active':'']">
         <button  style="color: snow;" @click="[$emit('menueSelect',menu),currentIndex=index,$router.push(menu.id)]" class="nav-link"  >{{menu.label}}</button>
        </li>
      </template>
    </ul>
  </div>

  <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto custom-ul-style">
        <template v-for="(menu, index) in menuList" :key="menu.id">
          <li class="nav-item" :class="{ active: index === currentIndex }">
            <button @click="handleMenuSelect(menu, index)" class="nav-link">{{ menu.label }}</button>
          </li>
        </template>
      </ul>
  </div> -->
  <div style="font-size: x-large; color:snow;font-style: italic;">
    Welcome:  {{user.username}}
    <button type="button" class="btn btn-danger" @click="$emit('logout')">Logout</button>
  </div>
<!-- </div> -->
</nav>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'NavBar',
    data(){
      return {
        currentIndex:0,
      }
    },
    props: ['menuList'],
    computed: {
      ...mapState({
            user: (state) => state.appModule.user
        })
    }

}
</script>

<style>
.navbar header{
  --navbar-padding-y: 15.5rem;
}
</style>