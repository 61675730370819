<template>
  <div class="card">
    
    <NavBar @logout="handleLogout" @menueSelect="(menu)=>defaultHome=menu.type" v-if="isLoggedIn" :menuList="menuData"/>
    <Login v-if="!isLoggedIn"/>
    <!-- <component v-if="isLoggedIn" :is="defaultHome"/> -->
<Alert/>
     <template v-if="isLoggedIn">
        <router-view></router-view>
     </template>
  </div>
</template>

<script>
import Login from './Login.vue';
import NavBar from './NavBar.vue';
import Alert from './Alert.vue';
import { mapState,mapMutations } from 'vuex';
// import DashBoard from './DashBoard.vue';
// import About from './About.vue';
import Registration from './Registration.vue';
import UserManagement from './UserManagement.vue';
// import Report from './Report.vue';
import Loader from './Loader.vue';
import MapComponent from './MapComponent.vue';
export default {
    name: 'Home',
    data(){
        return {
            defaultHome: 'DashBoard',
            menuData: [
                {
                    id: 'dashboard',
                    type: 'DashBoard',
                    label: 'Dash Board'
                },
                {
                    id: 'device',
                    type: 'Device',
                    label: 'Connected Device'
                 },
                // {
                //     id: 'registration',
                //     type: 'Registration',
                //     label: 'Registration'
                // },
                {
                    id: 'report',
                    type: 'Report',
                    label: 'Report'
                },
                // {
                //     id:'map',
                //     type:'MapComponent',
                //     label:'MapComponent'
                // },
                {
                    id: 'user',
                    type: 'UserManagement',
                    label: 'User Management'
                }
            ]
        }
    },
    components: {
        Login,
        NavBar,
        // DashBoard,
        // Device,
        // Registration,
        // Report,
        Loader,
        Alert,
       // MapComponent
    },
    created(){
        let userInfo=sessionStorage.getItem('loginInfo');
        if(userInfo){
            let user = JSON.parse(userInfo);
            console.log(user);
            this.loginSuccess(user);
        }
    },
    computed: {
        ...mapState({
            errorMessage: (state) =>state.appModule.errorMessage,
            isLoading: (state)=> state.appModule.isLoading,
            isLoggedIn: (state)=> state.appModule.isLoggedIn,
            isLoggedInFailed: (state)=> state.appModule.isLoggedInFailed
        })
    },
    methods: {
        ...mapMutations(['logout','loginSuccess']),
        handleLogout(){
            this.logout();
            sessionStorage.setItem('loginInfo','');
            this.$router.push('login')
        }
    }

}
</script>

<style>

</style>