import axios from "axios";

export default {
  // namespaced: true,
  state: {
    isLoading: false,
    user: {},
    isLoggedIn: false,
    isLoggedInFailed: false,
    errorMessage: '',
    alerts: [],
  },
  getters: {},
  mutations: {
    loginSuccess(state,obj){
        state.user = obj;
        state.isLoggedIn = true;
    },
    loginFailed(state,errorText){
        state.errorMessage = errorText;
        state.isLoggedIn = false;
    },
    setLoading(state,flag){
      state.isLoading= flag;
    },
    setErrorMessage(state,errMsg){
    state.errorMessage= errMsg;
    },
    logout(state){
      state.isLoggedIn=false;
    },
    clearAlerts(state){
      state.alerts = [];
    },
    setAlertMessage(state,alert){
      state.alerts.push(alert);
    }
  },
  actions: {
    async loginUser({ commit }, obj) {
      commit('setLoading',true);
      await axios
        .post(process.env.VUE_APP_AUTH_API, obj, {
          headers: {
            Accept: "application/json"
          },
        })
        .then((res) => {
          commit('setLoading',false);
          if (res.status === 200) {
            commit("loginSuccess", res.data);
          } else {
            commit("loginFailed", res.statusText);
          }
        })
        .catch((er) => {
          commit('setLoading',false);
          commit("loginFailed", er);
        });
    },
    userLogout({state}){

    }
  },
};
