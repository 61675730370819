import { createLogger, createStore } from "vuex";
import appModule from "./modules/appModule";

const debug = process.env.NODE_ENV !== 'production'

const store = createStore({
    modules: {
        appModule:appModule
    },
    strict:debug,
    plugins: debug?[createLogger()]:[]
})

export default store;