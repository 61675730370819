<template>
    <select :name="name" :id="id" @change="handleSelect" >
        <option v-for="(op,index) in options" :key="index"   :value="op.value">
            <span @click="selectedOpt=op">{{ op.label}}</span>
        </option>
    </select>
</template>

<script>
export default {
    name: 'DropDown',
    data(){
        return {
            selectedOpt: {}
        }
    },
    created(){
        console.log('jj')
    },
    props: ['name','id','options'],
    methods: {
        handleSelect(e){
            this.$emit('selected',e.target.value)
        }
    }
}
</script>

<style lang="scss" scoped></style>