<template>
    <div class="container">
        <div class="row" v-if="devicelist.length>0">
            <Popup @close="isEditMode=!isEditMode" v-if="isEditMode" @updateFields="updateFields" :fields="tableFieldsCopy"/>
            <Popup @close="isAddMode=!isAddMode" v-if="isAddMode" @updateFields="addFields" :fields="tableFieldsCopy"/>
            <button type="button"  style="width: 10%;" class="btn btn-primary"  v-if="!isAddMode && !isEditMode" @click="addDevice">Add</button>
            <Table v-if="!isEditMode && !isAddMode" :fields="tableFields" :records="devicelistCopy" @handleOperation="handleOperation"></Table>
        </div>
        <div class="row" v-if="devicelist.length>0 && !isEditMode && !isAddMode">
            <div class="col-sm-6"><p class="float-start"></p></div>  
            <div class="col-sm-6 float-end"><paginate
                :page-count="paginationMeta.pageCount"
                :page-range="paginationMeta.pageRange"
                :margin-pages="paginationMeta.marginPages"
                :click-handler="clickCallback"
                :prev-text="paginationMeta.prevText"
                :next-text="paginationMeta.nextText"
                :container-class="paginationMeta.containerClass"
                :page-class="paginationMeta.pageClass"
                first-last-button="true"
                first-button-text="First"
                last-button-text="Last"
            >
            </paginate></div> 
        </div>
    </div>
</template> 



  
<script>
import { mapMutations, mapState } from 'vuex';
import Paginate from "vuejs-paginate-next";
import axios from 'axios';
import DropDown from './DropDown.vue';
import DatePicker from './DatePicker.vue';
import Table from './Table.vue';
import Popup from './Popup.vue';
export default {
    name: 'Device',
    props: [],
    components: {
    DropDown,
    DatePicker,
    Table,
    Paginate,
    Popup
},
    data() {
        return {
            // dropDownOptions: [],
            // currentDeviceSelected: '',
            currentPage: 1,
            pageSize: 10,
            paginationMeta: {
                pageCount: 0,
                pageRange: 5,
                marginPages: 2,
                prevText: 'Prev',
                nextText: 'Next',
                containerClass: 'pagination',
                pageClass: 'page-item'

            },
            rangeApiObj: {
                range1: '',
                range2: ''
            },
            devicelist: [],
            devicelistCopy: [],
            tableFields:[
                {label: 'Device ID',path: 'deviceId',type: 'data',editCss: '',isNonEditable: true},
                {label: 'IoT Device Name',type: 'data',path: 'customerName',editCss:''},
                {label: 'Lattitude',type: 'data',path: 'latitude',editCss:''},
                {label: 'Longitude',type: 'data',path: 'longitude',editCss:''},
                {label: 'Status',type: 'data',path: 'status',editCss:''},
                {label: 'Address',type: 'data',path: 'address',editCss:''},
                {label: 'Operation',operations:["edit","delete"],type: 'operation',editCss:''}
            ],
            tableFieldsCopy: [],
            isEditMode: false,
            isAddMode: false,
        };
    },
    created() {
        this.fetchDevice();
    },
    computed: {
        ...mapState({
            user: (state) => state.appModule.user
        })
    },
    methods: {
        ...mapMutations(['setLoading','setAlertMessage']),
        clickCallback(obj){ // pagination call back
          console.log(obj)
          let endIndex = parseInt(obj)*this.pageSize-1;
          let startIndex = endIndex-this.pageSize;
          this.devicelistCopy = this.devicelist.slice(startIndex+1,endIndex+1);
          console.log('StartIndex ',startIndex,' endIndex ',endIndex)
        },
        addDevice(){
            this.tableFieldsCopy=JSON.parse(JSON.stringify(this.tableFields));
            this.tableFieldsCopy[0].isNonEditable=false;
            this.isAddMode=true;
        },
       async addFields(){
            let apiObj = {};
            this.tableFieldsCopy.forEach(f=>f.type=='data'?apiObj[f.path]=f.model:'');
            console.log(apiObj);
            this.tableFieldsCopy = [];
            this.isAddMode=false;
            this.setLoading(true);
           await axios.post(process.env.VUE_APP_REGISTER_DEVICE,apiObj, { headers: { 'Authorization': `Bearer ${this.user.token}` } } )
               .then(res=>{
                 this.setAlertMessage({type: 'success',message: 'Added Successfully'});
                 this.fetchDevice();
               })
               .catch(err=>{
                this.setAlertMessage({type: 'warning',message: 'Device  Failed to Add'})
               }).finally(()=>this.setLoading(false))
        },

        handleOperation(operation,selectedRecord){ // handle on click edit
            console.log(operation,selectedRecord)
            if(operation == 'edit'){
                this.tableFieldsCopy=JSON.parse(JSON.stringify(this.tableFields));
                this.tableFieldsCopy.forEach(f=>f.model=selectedRecord[f.path]);
                this.isEditMode=true;
            }
            if(operation == 'delete'){
                this.delectDevice(selectedRecord);
            }
        },
        async updateFields(){ // handle on save 
            let apiObj = {};
            this.tableFieldsCopy.forEach(f=>f.type=='data'?apiObj[f.path]=f.model:'');
            console.log(apiObj);
            this.tableFieldsCopy = [];
            this.isEditMode=false;
            this.setLoading(true);
           await axios.put(process.env.VUE_APP_UPDATE_DEVICE,apiObj, { headers: { 'Authorization': `Bearer ${this.user.token}` } } )
               .then(res=>{
                 this.setAlertMessage({type: 'success',message: 'Updated Successfully'});
                 this.fetchDevice();
               })
               .catch(err=>{
                this.setAlertMessage({type: 'warning',message: 'Update Failed'})
               }).finally(()=>this.setLoading(false))

        },
        async delectDevice({deviceId}){
            this.setLoading(true);
            await axios.delete(`${process.env.VUE_APP_DELETE_DEVICE+deviceId}`,{ headers: { 'Authorization': `Bearer ${this.user.token}` } })
            .then(res=>{
                this.setAlertMessage({type: 'success',message: 'Deleted Successfully'});
                 this.fetchDevice();

            })
            .catch(err=>{
                this.setAlertMessage({type: 'warning',message: 'Delete Failed'});
                 this.fetchDevice();

            }).finally(()=>{
                this.setLoading(false);
            });
        },
        async fetchDevice() {
            this.setLoading(true);
            await axios.get(process.env.VUE_APP_GET_SENSOR_LIST, { headers: { 'Authorization': `Bearer ${this.user.token}` } })
                .then(res => {
                    setTimeout(()=>this.setLoading(false),1)
                if (res.status === 200) {
                    this.devicelist=res.data;
                    this.paginationMeta.pageCount = this.devicelist.length%this.pageSize>0?Math.trunc(parseInt(this.devicelist.length)/parseInt(this.pageSize)+1):parseInt(this.devicelist.length)/parseInt(this.pageSize);
                    this.devicelistCopy = this.devicelist.slice(0,this.pageSize);
                }
            })
                .catch(er => {
                console.error(er);
                this.setLoading(false);
            });
        }
    }
}
</script>

<!-- <style lang="css" scoped>
@media (min-width: 1200px) {
    .container {
        width: 750px !important;
    }
}
    @media (min-width: 992px) {
    .container {
        width: 750px !important;
    }
}
    @media (min-width: 768px) {
    .container {
         width: 750px !important;
    }
}


.container{ width: 750px !important;}
.thumbnail{ width: 750px !important;}

</style> -->