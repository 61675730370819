<template>
  <div class="container">
    <!-- Header Section -->
    <div class="row mt-5">
      <div class="col-md-12 text-center">
        <h1 class="mb-4">IoT Data Dashboard</h1>
      </div>
    </div>

    <!-- Device Selection and Date Range Section -->
    <div class="row border p-4 mb-4">
      <div class="col-md-4">
        <label for="device" style="font-size: unset; font-weight: bold;">Select IoT Device</label>
        <DropDown id="device" name="device" :options="dropDownOptions" @selected="handleSelect" />
      </div>
      <div class="col-md-4">
        <div class="form-check">
          <input class="form-check-input" type="radio" id="range1" v-model="range" value="day_range" />
          <label class="form-check-label" style="font-size: unset; font-weight: bold;" for="range1">Day Range</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" id="range2" v-model="range" value="date_range" />
          <label class="form-check-label" style="font-size: unset; font-weight: bold;" for="range2">Custom Date</label>
        </div>
      </div>
      <div class="col-md-4">
        <div v-if="range == 'day_range'">
          <h4 style="font-size: unset; font-weight: bold;"><b>Day Range</b></h4>
          <DropDown id="device" name="device" :options="dropDowDaynOptions" @selected="handleChoosenRange"/>
          <!-- <DropDown id="dayRangeDropdown" name="dayRangeDropdown" :options="dropDownDayOptions" @selected="handleChoosenRange" /> -->
        </div>
        <div v-else>
          <h4 style="font-size: unset; font-weight: bold;"><b>Custom Date</b></h4>
          <div class="form-group">
            <label for="range11">Start Date</label>
            <DatePicker id="range11" name="range11" v-model="rangeApiObj.range1" @update:modelValue="handleRangeChange" />
          </div>
          <div class="form-group">
            <label for="range22">End Date</label>
            <DatePicker id="range22" name="range22" v-model="rangeApiObj.range2" @update:modelValue="handleRangeChange" />
          </div>
        </div>
      </div>
    </div>

    <!-- Chart Section -->
    <div class="row" v-if="!isLoading">
      <div class="row-md-4">
        <div class="card p-3">
          <h5 class="card-title">TDS Chart</h5>
          <highcharts :options="tdsChartOptions" />
        </div>
      </div>
      <div class="row-md-4">
        <div class="card p-3">
          <h5 class="card-title">Temperature Chart</h5>
          <highcharts :options="tempChartOptions" />
        </div>
      </div>
      <div class="row-md-4">
        <div class="card p-3">
          <h5 class="card-title">Flow Chart</h5>
          <highcharts :options="flowChartOptions" />
        </div>
      </div>
    </div>
    <div class="row" v-if="!isLoading">
          <MapComponent :latt="mapData.latt" :longt="mapData.longt"></MapComponent>
      
    </div>

    <!-- Loading Spinner Section -->
    <div v-if="isLoading" class="row mt-5">
      <div class="col-md-12 text-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- <style scoped>
  /* Add your custom CSS styles here */
  .border {
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
  }

  .card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
</style> -->

<style scoped>
.container {
  max-width: 1325px;
}
  /* Header Styles */
  .mt-5 h1 {
    color: #3498db; 
    margin-top: -3rem !important;/* Header text color */
  }

  /* Border Styles */
  .border {
    border: 1px solid #e74c3c; /* Border color */
    border-radius: 10px;
    background-color: #f2f2f2; /* Background color */
  }

  /* Device Selection Styles */
  /* label {
    color: #27ae60; /* Label text color 
  } */

  .form-check-label {
    color: #160d05; /* Radio button label text color */
  }

  .form-check-input:checked {
    background-color: #27ae60; /* Checked radio button background color */
  }

  /* Chart Card Styles */
  .card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    background-color: #ecf0f1; /* Card background color */
  }

  .card-title {
    color: #e74c3c; /* Card title color */
  }

  /* Loading Spinner Styles */
  .spinner-border {
    color: #f39c12; /* Spinner color */
  }
</style>

<script>
import DropDown from './DropDown.vue';
import axios from 'axios';
import { mapState, mapMutations } from 'vuex';
import DatePicker from './DatePicker.vue';
import MapComponent from './MapComponent.vue';

export default {
    name: 'DashBoard',
    data() {
    return {
      range: "day_range",
      chartResponse: {},
      rangeApiObj: {
        range1: "",
        range2: "",
      },
      currentDeviceSelected: "",
      dropDownOptions: [],
      dropDowDaynOptions: [{ name: "1 Day", value: "1" }],
      tdsChartOptions: {
        chart: {
          type: "line",
        },
        title: {
          text: "Total Dissolved Solids",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          categories: [
           
          ],
        },
        yAxis: {
          title: {
            text: "TDS",
          },
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
            },
            enableMouseTracking: false,
          },
        },
        series: [
          {
            name: "",
            data: [],
          },
          {
            name: "",
            data: [],
          },
        ],
      },
      tempChartOptions: {
        chart: {
          type: "line",
        },
        title: {
          text: "Temperature",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          title: {
            text: "TEMP",
          },
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
            },
            enableMouseTracking: false,
          },
        },
        series: [
          {
            name: "",
            data: [],
          },
          {
            name: "",
            data: [],
          },
        ],
      },
      flowChartOptions: {
        chart: {
          type: "line",
        },
        title: {
          text: "Flow",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          title: {
            text: "FLOW",
          },
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
            },
            enableMouseTracking: false,
          },
        },
        series: [
          {
            name: "",
            data: [],
          },
          {
            name: "",
            data: [],
          },
        ],
      },
      mapData: {
        latt: 40.689247,
        longt: -74.044502,
      },
      sensorinfo:[]
    };
  },
    
    components: {
    DropDown,
    DatePicker,
    MapComponent
},
computed: {
    ...mapState({
        user: (state)=> state.appModule.user,
        isLoading: (state) => state.appModule.isLoading,
    })
},
mounted(){
    this.fetchDevice();
    this.bindDropDowDaynOptions();
    let yest = new Date();
    yest.setDate(yest.getDate()-1);
    setTimeout(()=>this.handleChoosenRange(yest.toISOString().substring(0,19).replaceAll('T',' ')),1000*1);
},
    methods: {
        ...mapMutations(['setLoading']),
       async fetchDevice(){
            this.setLoading(true);
          await  axios.get(process.env.VUE_APP_GET_SENSOR_LIST,{headers:{'Authorization': `Bearer ${this.user.token}`}})
             .then(res=>{
              
                this.setLoading(false);
                if(res.status === 200){
                  this.sensorinfo=res.data;
                  
                this.dropDownOptions=  res.data.map((rec,index)=>{
                    if(index == 0){
                            this.currentDeviceSelected = rec.deviceId;
                            this.mapData.latt=parseFloat(rec.latitude);
                            this.mapData.longt=parseFloat(rec.longitude);
                        }
                  return { value:rec.deviceId,label:rec.customerName}
                })
                }
             })
             .catch(er=>{
               console.error(er);
               this.setLoading(false);
             })
        },
        handleSelect(value){ // handler for onSelect device
          this.currentDeviceSelected = value;
          this.fetchChartData({...this.rangeApiObj,deviceId:this.currentDeviceSelected});
          this.sensorinfo.filter(div=>div.deviceId==value).forEach(div=>{
            this.mapData.latt=parseFloat(div.latitude);
            this.mapData.longt=parseFloat(div.longitude);
          });
        },
        bindDropDowDaynOptions(){
           this.dropDowDaynOptions = [1,7,30].map(day=>{
            let currentDate = new Date();
            currentDate.setDate(currentDate.getDate()-parseInt(day));
            //return {value:`${currentDate.getFullYear()}-${currentDate.getMonth().toString().padStart(2, '0')}-${currentDate.getDay().toString().padStart(2, '0')}`,"label":`${day} Day ago`}
            return {value:currentDate.toISOString().replaceAll('T',' ').substring(0,19),"label":`${day} Day ago`}
           })
        },
        handleChoosenRange(rangeDate){ // from DropDown
            console.log(rangeDate)
            this.fetchChartData({...this.rangeApiObj,deviceId:this.currentDeviceSelected,rangeDate}); 
        },
        handleRangeChange(){ // from datepicker
           this.fetchChartData({...this.rangeApiObj,deviceId:this.currentDeviceSelected}); 
            
        },
       async fetchChartData({deviceId,range1,range2,rangeDate}){ // TDS DATA
        this.setLoading(true);
        let fromDate = '';
        let toDate = '';
            if(!deviceId){return;}
            if(rangeDate){
                fromDate = rangeDate;
                toDate = new Date().toISOString().substring(0,19).replaceAll('T',' ');
            }else{
                if(range1 && range2){
                    fromDate = new Date(range1).toISOString().substring(0,19).replaceAll('T',' ');
                    toDate = range2 == new Date().toISOString().substring(0,10) ? new Date().toISOString().substring(0,19).replace('T',' ') : new Date(range2).toISOString().substring(0,19).replaceAll('T',' ');
                }else{
                    return;
                }
            }
            let reqParams =`?deviceId=${deviceId}&fromDate=${fromDate}&toDate=${toDate}`;
            this.fetchTempChartData(reqParams);
            this.fetchFlowChartData(reqParams);

            await axios
        .get(process.env.VUE_APP_GET_TDS + reqParams, {
          headers: { Authorization: `Bearer ${this.user.token}` },
        })
        .then((res) => {
          if (res.status === 200) {
            this.chartResponse = res.data;
            this.chartResponse.dateTime.forEach((el) => {
              return new String(el);
            });
            this.tdsChartOptions.xAxis.categories = res.data.dateTime;
            this.tdsChartOptions.series = res.data.seriesData.map((data) => {
              return { name: data.sensorName, data: data.sensorData };
            });
          }
        })
          .catch(er=>console.log(er))
        },
        async fetchTempChartData(reqParams) {
      await axios
        .get(process.env.VUE_APP_GET_TEMP + reqParams, {
          headers: { Authorization: `Bearer ${this.user.token}` },
        })
        .then((res) => {
          if (res.status === 200) {
            this.chartResponse = res.data;
            this.chartResponse.dateTime.forEach((el) => {
              return new String(el);
            });
            this.tempChartOptions.xAxis.categories = res.data.dateTime;
            this.tempChartOptions.series = res.data.seriesData.map((data) => {
              return { name: data.sensorName, data: data.sensorData };
            });
          }
        })
        .catch((er) => console.log(er));
        this.setLoading(false);
    },
    async fetchFlowChartData(reqParams) {
      await axios
        .get(process.env.VUE_APP_GET_FLOW + reqParams, {
          headers: { Authorization: `Bearer ${this.user.token}` },
        })
        .then((res) => {
          if (res.status === 200) {
            this.chartResponse = res.data;
            this.chartResponse.dateTime.forEach((el) => {
              return new String(el);
            });
            this.flowChartOptions.xAxis.categories = res.data.dateTime;
            this.flowChartOptions.series = res.data.seriesData.map((data) => {
              return { name: data.sensorName, data: data.sensorData };
            });
          }
        })
        .catch((er) => console.log(er));
    }

    }

}
</script>

<style>
.highcharts-credits {
display: none !important;
}
.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #FFF;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 3px solid;
  border-color: #FF3D00 transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
</style>