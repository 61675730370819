<template>
  <Home></Home>
</template>

<script>

import Home  from './components/Home.vue';


export default {
  name: 'App',
  components: {

    Home,
  }
}
</script>

