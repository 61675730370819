<template>
    <div class="container">
        <div class="row" v-if="userList.length>0">
            <Popup/>
            <Table :fields="tableFields" :records="userListCopy"></Table>
        </div>
        <div class="row" v-show="userList.length>0">
            <div class="col-sm-6"><p class="float-start"></p></div>  
            <div class="col-sm-6 float-end"><paginate
                :page-count="paginationMeta.pageCount"
                :page-range="paginationMeta.pageRange"
                :margin-pages="paginationMeta.marginPages"
                :click-handler="clickCallback"
                :prev-text="paginationMeta.prevText"
                :next-text="paginationMeta.nextText"
                :container-class="paginationMeta.containerClass"
                :page-class="paginationMeta.pageClass"
                first-last-button="true"
                first-button-text="First"
                last-button-text="Last"
            >
            </paginate></div> 
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Paginate from "vuejs-paginate-next";
import axios from 'axios';
import DropDown from './DropDown.vue';
import DatePicker from './DatePicker.vue';
import Table from './Table.vue';
import Popup from './Popup.vue';
export default {
    name: 'UserManagement',
    props: [],
    components: {
    DropDown,
    DatePicker,
    Table,
    Paginate,
    Popup
},
    data() {
        return {
            // dropDownOptions: [],
            // currentDeviceSelected: '',
            currentPage: 1,
            pageSize: 10,
            paginationMeta: {
                pageCount: 0,
                pageRange: 5,
                marginPages: 2,
                prevText: 'Prev',
                nextText: 'Next',
                containerClass: 'pagination',
                pageClass: 'page-item'

            },
            rangeApiObj: {
                range1: '',
                range2: ''
            },
            userList: [],
            userListCopy: [],
            tableFields:[
                {label: 'First Name',path: 'firstName'},
                {label: 'Last Name',path: 'lastName'},
                {label: 'UserName',path: 'userName'},
                {label: 'Role',path: 'role'}
            ]
        };
    },
    created() {
        this.fetchUser();
    },
    computed: {
        ...mapState({
            user: (state) => state.appModule.user
        })
    },
    methods: {
        ...mapMutations(['setLoading']),
        clickCallback(obj){ // pagination call back
          console.log(obj)
          let endIndex = parseInt(obj)*this.pageSize-1;
          let startIndex = endIndex-this.pageSize;
          this.userListCopy = this.userList.slice(startIndex+1,endIndex+1);
          console.log('StartIndex ',startIndex,' endIndex ',endIndex)
        },
        async fetchUser() {
            this.setLoading(true);
            await axios.get(process.env.VUE_APP_USER, { headers: { 'Authorization': `Bearer ${this.user.token}` } })
                .then(res => {
                    setTimeout(()=>this.setLoading(false),1)
                if (res.status === 200) {
                    this.userList=res.data;
                    this.paginationMeta.pageCount = this.userList.length%this.pageSize>0?Math.trunc(parseInt(this.userList.length)/parseInt(this.pageSize)+1):parseInt(this.userList.length)/parseInt(this.pageSize);
                    this.userListCopy = this.userList.slice(0,this.pageSize);
                }
            })
                .catch(er => {
                console.error(er);
                this.setLoading(false);
            });
        }
    }
}
</script>

<style lang="scss" scoped></style>