<template>
  <section class="login vh-100">
      <div class="container py-5">
   <div class="row loginrow d-flex justify-content-center align-items-center">
     <div class="col-sm-12 col-md-8 col-lg-6 col-xl-5 fmcol">
       <div class="card shadow-2-strong" style="border-radius: 1rem; background-color: rgb(255 255 255);">
         <div class="card-body p-5 text-center">
<form @submit.prevent="handleFormSubmit">
           <h3 class="mb-4"><img src="../assets/pws-logo.png" width="200" height="80"></h3>

           <div class="form-outline mb-4">
            <label for="login__username" class="lbl">
              <i class="fa fa-user w3-xxxsmall usricn"></i>
            <span class="hidden">Username</span>
          </label>
           
             <input type="text" id="typeEmailX-2" v-model="username" class="form-control form-control-lg" placeholder="Username" />
             <!-- <label class="form-label hidden" for="typeEmailX-2">Username</label> -->
           </div>

           <div class="form-outline mb-4">
            <label for="login__password" class="lbl">
              <i class="fa fa-lock w3-xxxsmall lockicn"></i>
            <span class="hidden">Password</span>
          </label>
            
             <input type="password" v-model="password" id="typePasswordX-2" class="form-control form-control-lg" placeholder="Password"/>
           
           </div>



           <button class="btn btn-primary btn-lg btn-block loginbtn" type="submit">Login</button>
</form>
        <div class="fpwd pt-4">
          <a href="/forgotpassword/" class="fpwd-txt">Forgot Your Password?</a>
        </div>
           <!-- <hr class="my-10">
           <h6 style="font: 1em 400;">MKS VISION.COM </h6> -->
           

         </div>
       </div>
     </div>
   </div>
 </div>
</section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
   name: 'Login',
   data(){
       return {
           "username": "",
           "password": ""
       }
   },
   components: {

   },
   computed: {
       ...mapState({
           errorMessage: (state) =>state.appModule.errorMessage,
           isLoading: (state)=> state.appModule.isLoading,
           isLoggedIn: (state)=> state.appModule.isLoggedIn,
           isLoggedInFailed: (state)=> state.appModule.isLoggedInFailed,
           user: (state)=> state.appModule.user
       })
   },
   methods: {
       ...mapActions(["loginUser"]),
      async handleFormSubmit(){
        await this.loginUser({username: this.username,password:this.password});
        if(this.isLoggedIn){
          this.$router.push('dashboard');
          sessionStorage.setItem('loginInfo',JSON.stringify(this.user));
        }
       }
   }

}
</script>

<style>
.login{
background-image: url(../assets/iot_bg.jpg);
background-position: center;
background-size: cover;
width: 100%;
height: 100%;
display: flex;
  justify-content: center;
  align-items: center;
}
.form-control-lg{
border-radius: 0 5px 5px 0!important;
font-size: 16px!important;
}
.gradient-custom-2 {
/* fallback for old browsers */
background: #fccb90;

/* Chrome 10-25, Safari 5.1-6 */
background: -webkit-linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);

/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
}

.hidden {
 display: none;
}
.lbl{
padding: 8.1px 15px;
  background-color: #ededed;
  border: var(--bs-border-width) solid var(--bs-border-color);
}
.form-outline{
display: flex;
justify-content: center;
align-items: center;
}
.form-control-lg:focus {
  color: var(--bs-body-color);
  background-color: #ededed;
  border: var(--bs-border-width) solid var(--bs-border-color);
  outline: 0;
  box-shadow: none!important;
}
.loginbtn {
  background-color: #003fa7!important;
  border: none!important;
  border-radius: 5px!important;
  font-size: 17px!important;
  text-transform: uppercase!important;
  font-weight: 500!important;
}

.loginbtn:hover {
  background-color: #0459e0!important;
  border: none;
  border-radius: 5px!important;
  font-size: 17px!important;
  text-transform: uppercase!important;
  font-weight: 500!important;
}

a.fpwd-txt {
  font-size: 15px;
  text-decoration: none;
  font-weight: 500;
  color: rgb(54, 54, 54);
}
a.fpwd-txt:hover {
  font-size: 15px;
  text-decoration: none;
  font-weight: 500;
  color: rgb(0, 81, 156);
}
@media (min-width: 768px) {
.gradient-form {
height: 100vh !important;
}
}
@media (min-width: 769px) {
.gradient-custom-2 {
border-top-right-radius: .3rem;
border-bottom-right-radius: .3rem;
}
}
.navbar header{
--navbar-padding-y: 15.5rem;
}
</style>