import { createRouter, createWebHistory } from "vue-router";
import DashBoard from '../components/DashBoard.vue';
import Device from '../components/Device.vue';
import Report from '../components/Report.vue';
import Login from '../components/Login.vue';
import UserManagement from '../components/UserManagement.vue';
import MapComponent from '../components/MapComponent.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/dashboard', component: DashBoard },
        { path: '/report', component: Report },
        { path: '/device', component: Device },
        { path: '/login', component: Login },
        { path: '/user',component:UserManagement},
        { path: '/map',component:MapComponent}
    ]
});

export default router;